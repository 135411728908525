.navW {
    margin-bottom: 15px;
    text-align: center;
}

.dot {
    color: black;
    cursor: pointer;
    font-size: 36px;
    line-height: 1;
    margin: 0 15px;
    opacity: .4;
    text-shadow: none;
    transition: opacity 1s ease,
        text-shadow 1s ease;
    will-change: opacity, text-shadow;
}



.activeW {
    color: green;
    opacity: 1;
    text-shadow: 0 0px 8px;
}

.rsw-wrapper {
    min-height: 400px !important;
}

/** Progress Bar */
@progress-height: 5px;
@duration: 3s;

/* @keyframes stripes {
    from { background-position: 1rem 0 }
    to { background-position: 0 0 }
} */

@keyframes rainbow {
    0% { background-color: var(--red) }
    20% { background-color: var(--orange) }
    40% { background-color: var(--yellow) }
    60% { background-color: var(--green) }
    80% { background-color: var(--blue) }
    100% { background-color: var(--indigo) }
}

@keyframes grow {
    from { width: 0% }
    to { width: 100% }
}

/* .progress-wrapper {
    padding-top: 25%;
} */

