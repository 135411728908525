.cardMod {
    margin-top: 3vh;
    text-align: center;
    height: auto;
    width: 11vw;
    background: #1c432526;
    vertical-align: middle;
    padding: 10px;
   
    border-radius: 20%;
    transition: 0.1s ease-in-out;
}

@media (max-width: 768px) {
    .cardMOd {
        margin-top: 3vh;
        text-align: center;
        height: 22vh;
        min-width: 60%;
        background: #1c432526;
        vertical-align: middle;
        padding: 10px;
        left:21vw;
        border-radius: 11%;
        transition: 0.1s ease-in-out;
    }
}

    .cardMod:hover {
        

        cursor: pointer;
        background-color: #ffb52dbf;
        transition: 0.1s ease-in-out;
         color: white; 
        
    }

        .cardMod:hover span {
            transition: 0.25s ease-in-out;
            color: white!important;
        }


        .disabled {
            color: grey;
            background-color: rgb(0, 0, 0, 50%);
            pointer-events: none;
        }
        