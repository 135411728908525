#exhibit-area {
  padding: 2.5rem;
}

#exhibit-carousel {
  padding: 2rem;
}

.exhibit-details-col {
  padding: 0.5rem 5rem;
}

#exhibit-carousel img {
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  height: 75vh;
}

#exhibit-tabs-area {
  background-color: #f9f9ff;
}

.exhibit-tabs-container {
  min-height: 50vh;
}

.exhibit-tabs-container p {
  font-size: 1.2rem;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .exhibit-details-col {
    padding: 3rem 0.5rem 0.5rem 0.5rem
  }

  .exhibit-details-col h3 {
    margin: 0;
  }

  .exhibit-details-col .pt-5 {
    padding-top: 2rem !important;
  }
}

@media only screen and (max-width: 768px) {
  #exhibit-area {
    padding: 1rem ;
  }

  .exhibit-details-col {
    padding: 3rem 0.5rem 0.5rem 0.5rem
  }

  .exhibit-details-col h3 {
    margin: 0;
  }

  .exhibit-details-col .pt-5 {
    padding-top: 2rem !important;
  }
}
