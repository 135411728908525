:root {
    --modal-duration: 1s;
    --modal-color: #428bca;
}

@media (max-width: 1800px) {
    .nav-tabs .nav-item {
        font-size: 16px;
    }

    #imagesGallery td .content {
        top: 40% !important;
        right: 23% !important; 
    }

    .mediaForward {
        margin-right:23vw !important;
    }

    .pageHead h4 img {
        margin-left:8vw;
    }

    

    .au {
        margin-right:2.5rem !important;
    }

} 

#transferMediaGal {
    margin-bottom: 7%;
}

#imagesGallery td .content {
    top: 47% !important;
    right: 20% !important; 
}

.spinnerShowMedia {
    position: absolute;
    left: 49%;
    top:20%;
}

.spinnerGallery {
    position: absolute;
    
    left: 49%;
    
    top:45%;
}


img:hover {
    /* transition: all ease-in-out 0.5s; */
    -moz-transform: scale(1.03);
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }


.titleMed {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 1rem;
    padding-right: 1rem;
}

/*.nav-tabs {
    border-radius:20px;
}

.nav-link {
    border-radius:20px;
}*/

.modalMedia {
    display: none;
    position: fixed;
    left: 0vh!important;
    z-index: 1;
    top: 0vh;
    height: 100%;
    width: 100%!important;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

#media::-webkit-scrollbar {
    width: 0.4em;
    height:0.7rem;
    cursor: pointer;
}

#media::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 10px #ffffff;
    cursor: pointer;
}

#media::-webkit-scrollbar-thumb {
    background-color: #1c4325;
    outline: none;
    border: 1px solid #fff;
    border-radius: 20px;
    cursor: pointer;
}


.languageIcons {
    height: 1.6rem !important;
    width: 1.6rem !important;
}

.fa-arrow-left, .fa-arrow-right {
    padding: 4px !important;
}

    .modalMedia-content

{
    margin: 2.5% auto;
    width: 52%!important;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
    animation-name: modalopen;
    animation-duration: var(--modal-duration);
}

.form-check-input:checked {
    background-color: #198754;
    border-color: #198754;
}


.modalMedia-body {
    padding: 10px 20px;
    background: #fff;
    border-radius: 20px;
}

@keyframes modalopen {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.labelUpload {
    border: 2px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background: #e5e5e5;
    border-radius: 20px;
}


.closeModalDefault {
    float: right;
    font-size: 38px;
    cursor: pointer;
    color: #aaa;
    float: right;
    font-weight: bold;
}

    .closeModalDefault:hover,
    .closeModalDefault:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }





.closeModal {
    float: right;
    font-size: 38px;
    cursor: pointer;
    color: #aaa;
    float: right;
   
    font-weight: bold;
}

    .closeModal:hover,
    .closeModal:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }

.closeModalLocal {
    float: right;
    font-size: 38px;
    cursor: pointer;
    color: #aaa;
    float: right;
    font-weight: bold;
}

    .closeModalLocal:hover,
    .closeModalLocal:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }

    html {
scroll-behavior: smooth;
}


.buttonsForMed {
    display: flex;
    flex-direction: column;
    width: 15%;
}

#transferMedia {
    margin-left: 42%;
    margin-top: -2%;
    margin-bottom: 3%;
}

#transferMediaDefault {
    margin-left: 42%;
    margin-top: -2%;
    margin-bottom: 3%;
}

input:checked + .slider {
    background-color: #0daf35!important;
}

.centered {
    text-align:center;
}


#xxx:focus {
    outline: none;
}

.inputDesc:focus {
    outline: none;
}

.heightened {
    height: 13rem;
}

/*for the content*/
#table_exhibits {
    width: 100% !important;
    border: 1px solid #d2d2d2;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 50px;
    box-shadow: 2px 2px 20px 0px;
    padding-bottom: 16px;
}

/* @media (min-width: 780px) {
    .modal-dialog {
        max-width: 45vw !important;
        margin: 1.75rem auto !important;
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 50vw !important;
        margin: 1.75rem auto !important;
    }
} */

.blackLabel {
    color: #1c4325;
    /*margin-left: 5vw;*/
}


.fa-map-marker-alt,
.fa-dot-circle {
    color: #5bc0de;
}

/*Jumbotron*/
.jumbotron {
    background-color: transparent;
    margin: 0;
    padding: 10px;
}

    .jumbotron h1 {
        letter-spacing: 2.5px;
        font-size: 3.5em;
    }

    .jumbotron h1,
    .jumbotron p {
        text-align: center;
    }

/*map*/

/*output box*/


#mode {
    color: black;
}

#map {
    height: 555px;
    width: 100%;
    height: 517px;
    width: 78%;
    margin-left: 12vw;
    margin-top: -40vh;
}

.ss-value {
    color: black !important;
    background: orange !important;
}

#categoriesDropdown {
    margin-bottom: -3vh;
    border-radius: 20px;
}

#modalStyle {
    top: 19vh;
    width: 66vw;
    left: -2vw;
}

#translationArray {
    width: 15%;
}

/* .form-group {
    margin-bottom: -1.5rem;
} */

#imagesGallery {
    display: flex;
    justify-content: space-evenly;
    background: #e8e8e8 !important;
    /* width: inherit; */
}

#result {
    display: flex;
    /*overflow-x: scroll;*/
}

/* .outlineNone {
    margin-top: -1vh;
    width: 160%;
    margin-left: -7vw;
    border: none;
    border-bottom: 1px solid black;
    text-align: center;
    transition: .7s ease-in-out;
    font-size: 23px;
    border-radius: 0px !important;
} */

    .outlineNone:focus {
        outline: none;
        border-bottom: 1px solid #f7aa47;
        transition: .4s ease-in-out;
        color: #f7aa47;
    }

/* .sameLine {
            display: flex;
            flex-direction: row;
            justify-content:center;
        }*/


#nameOfObject {
    display: inline-flex;
    margin-left: 25vw;
}

input {
    border-radius: 20px;
}

#searchBar {
    border-radius: 0!important;
}

/* #objectUpdateSerialNumber {
    border-radius: 0px !important;
    border: 1px solid #d1c9c9;
} */

    /* #objectUpdateSerialNumber:focus-visible {
        border: 1px solid #d1c9c9;
        color: orange;
    } */

/* #updateObject {
    background: #f7aa47;
    color: white;
    margin-left: 10vw;
    margin-top: -3vh;
} */

.navPhoto {
    background-size: 20px;
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    background-position: center;
    margin-right: 6px;
}

.footerButt {
    margin-right: 27vw;
}

#files {
    border-radius: 0px !important;
}

#submit {
    border-radius: 20px !important;
}

/* #divPresentationsDropdown {
    margin-left: 25vw;
    font-size: 20px;
    padding: 5px;
} */

    /* #divPresentationsDropdown label {
        font-size: 25px !important;
    } */

#saveFields {
    margin-left: 37vw;
    margin-top: 5vh;
    margin-bottom: 2vh;
}

.table-dark {
    background: #e8e8e8;
}

/*styles for testing new code code */



.thumbnails {
    list-style: none;
    font-size: 0;
    display: flex;
}

    .thumbnails tr {
        display: inline-block;
        /* width: 23%;
        margin-left: 3%; */
        text-align: center;
        vertical-align: middle;
    }

        .thumbnails tr:hover .item-hugger {
            background: white;
        }

            .thumbnails tr:hover .item-hugger .title {
                color: #000;
            }

    .thumbnails input[name="select"] {
        display: none;
    }

    .thumbnails .item-hugger {
        position: relative;
        border-radius: 20px;
        width: 12.5vw;
        /*height: 19.5vh;*/
        margin: 20px 0;
        background: #edede8;
        transition: all 150ms ease-in-out;
    }

    .thumbnails label {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
    }

    .thumbnails .title {
        padding: 10px 0 0;
        font-size: 18px;
        color: #555;
        transition: all 150ms linear;
    }

    .thumbnails .thumb-image {
        max-width: 11vw;
        min-width: 10vw;
        max-height: 15.4vh;
        padding: 20px 0;
    }

    .thumbnails .content {
        position: absolute;
        /*bottom: 36vh;*/
        /*left: 0;*/
        max-width: 600px;
        max-height: 500px;
        /* padding: 50px; */
        transition: all 150ms linear;
        display: none;
        flex-direction: column;
        justify-content: center;
    }

        .thumbnails .content .title {
            font-size: 23px;
            font-weight: 400;
            display: inline-block;
            color: #555;
            /* border-bottom: 6px solid #fe7701;*/
            padding: 50px 10px 0;
            text-transform: uppercase;
        }

    .thumbnails input[name="select"]:checked + .item-hugger {
        /*height: 150px;*/
        /* margin: 0;*/
        background: white;
        
    }

    .thumbnails input[name="select"]:checked ~ .content {
        display: flex;
    }

.white-box {
    background: white;
    height: 500px;
}

/*img {
            max-width: 41vw;
            max-height: 36vh;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        video {
            max-width: 100%;
            max-height: 36vh;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }*/

.previewPhoto {
    width: 30vw;
    height: 31vh;
    display: block;
   margin-left: 16%;
   border-radius:12px;
    /* margin-right: auto; */
}

@media (max-width: 1800px) {
    .previewPhoto {
        /* width: 30vw;*/
        margin-top: 20%;
        height: 31vh;
        display: block;
        margin-left: 6%;
        /* margin-right: auto;*/
    }

    .previewVideo {
        width: 100%;
        max-width: 20vw;
        height: 100%;
        max-height: 27vh;
        /*display: block;*/
        /*margin-top: 9vh;*/
        margin-left: 4vw;
        margin-right: 3vw;
    }

    .deleteBtn {
        margin-left: 10% !important;
    }

    .thumbnails .content {
        position: absolute;
        margin-top: 0rem;
        /* bottom: 36vh; */
        /* left: 0; */
        top: 29% !important;
        right: 14vw !important;
        max-width: 600px;
         max-height: 350px; 
        /* padding: 50px; */
        transition: all 150ms linear;
        flex-direction: column;
        justify-content: center;
    }

    .thumbnails .item-hugger {
        position: relative;
        border-radius: 20px;
        width: 12vw;
        /* height: 30%; */
        margin: 20px 0;
        background: #edede8;
        transition: all 150ms ease-in-out;
    }
}

.previewVideo {
    width: 100%;
    max-width: 20vw;
    height: 100%;
    max-height: 27vh;
    /*display: block;
    margin-top: 1vh;
    margin-left: 6vw;
    margin-right: auto;*/
}

.vi {
    margin-left:1%!important;
}

.previewAudio {
}

.deleteBtn {
   
    margin-top: 0.5rem;
    /* background: orange; */
    color: #db8619;
}

.table>:not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    background-color: transparent!important;
    border-bottom-width: 1px;
    /* box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); */
}