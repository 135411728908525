/* * {
   box-sizing: border-box;
   margin: 0;
   padding: 0; 
} */

ol, ul {
    padding-left: 0.6rem;
}

.xenagosHeader {
    height: auto;
    width: 100%;
}

.navbar {
    background-color: #1c4325;
    
    height: auto;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: inherit;
}

.menu-bars {
    margin-left : 2rem;
    font-size: 2rem;
    background: none;
}

.dropdown-lang {
    position: absolute; 
    z-index: 1000;
    display: block;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
}

.nav-menu {
    background-color: #1c4325;
    z-index: 999;
    width: 40vh;
    
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 10px;
    left: -100%;
    transition: 1050ms;
}


.nav-menu.active {
    left: 0!important;
    transition: 350ms;
}

.nav-text {

    display: flex;
    justify-content: start;
    align-items: center;
    /* padding: 8px 0px 8px 16px; */
    list-style: none;
    height: 8.9vh;

}

.nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    white-space: nowrap ;
    text-overflow: ellipsis;
    overflow: hidden;
}

.nav-text a img {
    width: 23%;
}

.nav-text a:hover {
    background-color: orange;
    transition: ease-in-out 200ms
}

.nav-menu-items {
    width: 100%;
}

.navbar-toggle {
    background-color: transparent;
    
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.sidebar_span {
    margin-left: 2vw;
}
/* span {
    margin-left: 16px;
} */