.AppCenter {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

button {
  border-radius: 18px!important;
}

select {
  border-radius: 20px!important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.tox-statusbar__branding,
.tox-promotion {
  display: none !important;
}

#select-multi-fields button {
  color: #fff !important;
  background-color: #0d6efd !important;
  border-color: #0d6efd !important;
  display: inline-block !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  text-align: center !important;
  border: 1px solid transparent !important;
  padding: 0.2rem 0.5rem !important;
  font-size: 1rem !important;
  border-radius: 0.25rem !important;
  transition: color 0.15s !important;
}

.App-link {
  color: #61dafb;
}

.react-bootstrap-table-pagination {
  text-align: start;
  min-height: 150px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fa-fw {
  text-align: center;
  width: 2 !important;
}
