.progress-bar {
    /* background-color: transparent!important; */
    transition: all 1s !important;
}

#pB {
    transition: all 1s !important;

}

.icon-btn.active {
    color: orange!important;
    font-weight: 380;
  
    /* background: orange!important; */
    /* transition: 0.45s !important; */
}

.statusSpan {
    color: black!important;
}