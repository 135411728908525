.swiper {
  z-index: 0 !important;
}

.imageSwiper {
  margin: 10px;
}

.imageSwiper img {
  width: 100%;
  height: 55vh;
  object-fit: cover;
}

.thumbnailSwiper img {
  width: 100%;
  height: 15vh;
  object-fit: cover;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .imageSwiper img{
    height: 40vh;
  }

  .thumbnailSwiper img {
    height: 12vh;
  }
}

@media only screen and (max-width: 768px) {
  .imageSwiper img{
    height: 35vh;
  }

  .thumbnailSwiper img {
    height: 8vh;
  }
}