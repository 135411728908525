html,
body {
  width: 100%;
  overflow-x: hidden;
}

.exhibitCard {
  width: 100%;
  height: auto;
}

.exhibitCard img {
  object-fit: cover;
  overflow: hidden;
  height: 34vh;
}

.category-card {
  transition: all 0.3s ease;
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.category-card:hover {
  box-shadow: 5px 6px 6px 2px #e9ecef;
  transform: scale(1.05);
}

.category-card img {
  object-fit: cover;
  width: 100%;
  height: 23vh;
}

.category-card-body {
  padding: 0.3rem;
}

.category-card-title {
  background-color: #00000077;
  padding: 0.3rem 0.5rem !important;
  color: #fff;
  border-radius: 15px;
  font-size: 18px;
}

.slick-slide img {
  object-fit: contain;
  overflow: hidden;
  height: 10vh;
}

.slick-slide .event-card {
  width: 100% !important;
  height: 100% !important;
}

.slick-next:before,
.slick-prev:before {
  color: #0d6efd !important;
}

.event-card-body {
  padding-top: 3vh;
  min-height: 16vh;
}

.event-card .card-img {
  margin-top: 1.5rem;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .exhibitCard img {
    height: 22vh;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .exhibitCard img {
    height: 22vh;
  }
}
