label {
    color: black;
    font-weight: 450;
    font-size: 20px;
}

/* .css-1rhbuit-multiValue {
    max-width: fit-content;
} */

/* 
.center {
    text-align: center;
} */

