.service-area {
  background-color: white;
  min-height: 45vh;
  display: flex;
}

.container-front {
  width: 100%;
}

.service-content {
  padding: 30px;
  background-color: #f9f9ff;
  border-radius: 5px;
}

.service-content h3 {
  color: #88d200;
}

.icon {
  padding: 10px;
  font-size: 40px;
  color: #88d200;
}

.service-area-2 {
  background-color: white;
  min-height: 35vh;
  display: flex;
}

.exhibits-area-front {
  min-height: 75vh;
  background-color: #f9f9ff;
}

.events-area {
  background-color: #f9f9ff;
  padding: 30px;
}