:root {
  --modal-duration: 1s;
  --modal-color: #428bca;
  --pagination-bg-color: #035918;
  --pagination-color: #fafafa;
}

body {
  overflow-x: visible !important;
}

@media (max-width: 1800px) {
  .nav-tabs .nav-item {
    font-size: 16px;
  }

  #imagesGallery td .content {
    top: 38% !important;
    right: 21% !important;
  }

  .mediaForward {
    margin-right: 23vw !important;
  }

  .pageHead h4 img {
    margin-left: 8vw;
  }

  .au {
    margin-right: 2.5rem !important;
  }
}

.titleMed {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 1rem;
  padding-right: 1rem;
}

.react-bootstrap-table-pagination {
  text-align: start;
  min-height: 50px;
}

.page-link {
  color: var(--pagination-bg-color) !important;
}

.page-link:hover {
  color: var(--pagination-bg-color) !important;
}

.page-item.active .page-link {
  color: var(--pagination-color) !important;
  background-color: var(--pagination-bg-color) !important;
}

.modalMedia {
  display: none;
  position: fixed;
  left: 28vh;
  z-index: 1;
  top: 1vh;
  height: 100%;
  width: 84%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

#media::-webkit-scrollbar {
  width: 0.4em;
  height: 0.7rem;
  cursor: pointer;
}

#media::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 10px #ffffff; */
  cursor: pointer;
}

#media::-webkit-scrollbar-thumb {
  background-color: #1c4325;
  outline: none;
  border: 1px solid #fff;
  border-radius: 20px;
  cursor: pointer;
}

#text-filter-column-title {
  height: 3vh;
}

.languageIcons {
  height: 1.6rem !important;
  width: 2rem !important;
}

.fa-arrow-left,
.fa-arrow-right {
  padding: 4px !important;
}

.modalMedia-content {
  margin: 10% auto;
  width: 60%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
  animation-name: modalopen;
  animation-duration: var(--modal-duration);
}

.modalMedia-body {
  padding: 10px 20px;
  background: #fff;
  border-radius: 20px;
}

@keyframes modalopen {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#text-filter-column-name {
  height: 3vh;
  font-size: 17px;
}

.labelUpload {
  border: 2px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background: #e5e5e5;
  border-radius: 20px;
}

.closeModalDefault {
  float: right;
  font-size: 38px;
  cursor: pointer;
  color: #aaa;
  float: right;
  font-weight: bold;
}

.closeModalDefault:hover,
.closeModalDefault:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.closeModal {
  float: right;
  font-size: 38px;
  cursor: pointer;
  color: #aaa;
  float: right;

  font-weight: bold;
}

.closeModal:hover,
.closeModal:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.closeModalLocal {
  float: right;
  font-size: 38px;
  cursor: pointer;
  color: #aaa;
  float: right;
  font-weight: bold;
}

.closeModalLocal:hover,
.closeModalLocal:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

html {
  scroll-behavior: smooth;
}

.buttonsForMed {
  display: flex;
  flex-direction: column;
  width: 15%;
}

#transferMedia {
  margin-left: 42%;
  margin-top: -2%;
  margin-bottom: 3%;
}

#transferMediaDefault {
  margin-left: 42%;
  margin-top: -2%;
  margin-bottom: 3%;
}

input:checked + .slider {
  background-color: #0daf35 !important;
}

#xxx:focus {
  outline: none;
}

.heightened {
  height: 13rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #1c4325;
  border-color: none;
}

.page-link {
  z-index: 3;
  color: black;
  /* background-color: #1c4325; */
}

#pageDropDown {
  color: var(--pagination-color);
  background-color: var(--pagination-bg-color);
}
