.media-gallery {
  cursor: pointer;
}

.media-gallery-items img {
  object-fit: cover;
  width: 100%;
  height: 25vh;
  border-radius: 2px;
}

.audio-media audio {
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .media-gallery-items img {
    object-fit: cover;
    max-height: 16vh;
  }
}

@media only screen and (max-width: 767px) {
  .media-gallery-items img {
    object-fit: cover;
    max-height: 20vh;
  }
}
