/* .react-bootstrap-table-pagination {
    margin-left: -8vw;
} */

.translationArr {
         /* margin-top: 3vh;  */
        font-size: 20px;
         /* margin-left: 4vh;  */
        cursor: pointer;
        list-style: none;
}

.txtArea {
    width: 100%!important;
}

.activeUl {
    color: orange;
}

.act {
    line-height:4vh;
    list-style: none;
}

.acti {
    line-height:4vh;
    list-style: none;
}

.boxesAdd {
    min-height: 55vh;
    /* box-shadow: 1px 2px 11px 0px #d2d2d2 !important; */
    margin-top: 1vh;
    margin-left: 1px;
    border: 1px solid #d2d2d2;
    box-shadow: 3px 2px 20px 0px;
    border-radius: 20px;
}

#title {
    margin-left: 1vw;
}

.inLine {
    display: flex;
    flex-direction: row;
}

form > .form-control {
    color: #BED7FA !important;
    background-color: #19374f !important;
    margin-bottom: 10px; }
  
.form-control { 
    
    display: block;
    width: 100%!important;
    padding: 0.375rem 0.75rem;
    border-radius: 12px;
    font-size: 20px;
    line-height: 1.5;
    color: black !important;
    background-color: #fff !important;
    background-clip: padding-box;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}



.pageHeader {
    font-size: 25px;
    color: orange;
    text-align: center;
    font-weight: bold;
}

.pageHead {
    width: 100%;
    height: 100%;
    margin-top: -4px;
    border-bottom: 1px solid gray;
    box-shadow: 0px 2px 9px 0px;
    background: #fefefe;
}

.form-check-input:checked {
    background-color: #198754;
    border-color: #198754;}

.outlineNone {
    font-size: 23px;
    text-align: center;
    border:none;
    /* border-bottom: 1px solid orange; */
    margin-bottom: 1vh;
    margin-top: 3vh;
}

.outlineNone:focus {
    outline: none; 
    font-size: 23px;
    text-align: center;
    border:none;
    /* border-bottom: 1px solid orange; */
    margin-bottom: 1vh;
    margin-top: 3vh;
}

#none {
    display: none;
}

.buttonModal {
    display: flex;
    flex-direction: row;
    margin-top: -5vh;
    margin-left: 45%;
}

.form-title {
    border-top: none;
    border-left: none;
    border-right: none;
    text-align: center;
    
}

.form-title:focus {
    border-top: none;
    border-left: none;
    border-right: none;
    text-align: center;
    outline:none!important;
}

#presName:focus {
    border-top: none;
    border-left: none;
    border-right: none;
    text-align: center;
    outline:none!important;
    
}

#presName {
    border-top: none;
    border-left: none;
    border-right: none;
    text-align: center;
}

#catName {
    border-top: none;
    border-left: none;
    border-right: none;
    text-align: center;
}


#catName:focus {
    border-top: none;
    border-left: none;
    border-right: none;
    text-align: center;
    outline:none!important;
    
}


.form-description {
    text-align: center;
}
/* 
.css-1rhbuit-multiValue {
    width: min-content;
} */

.sigamhdoul {
    width: min-content;
}

.css-1rhbuit-multiValue {
    border-radius: 7px!important;
}

.css-1s2u09g-control {
    border-radius: 13px!important;
}