@keyframes pop {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.editor {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.editorSection {
  border: 1px solid #e5e5e5;
  width: 30%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 2vh;
  padding: 2vw;
  border-radius: 20px;
  background-color: #edede8;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
}
.editorDevice {
  width: 20vw;
  height: 77vh;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow: hidden;
  overflow-y: scroll;
  border: 10px solid rgb(32, 32, 32);
  border-radius: 50px;
  background-image: url("../../images/bg\ -\ mobile.png");
  background-size: cover;
  background-position: center;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
}
.notch {
  background-color: rgb(32, 32, 32);
  width: 4vw;
  height: 2vh;
  border-radius: 200px;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  position: absolute;
}

.editorDevice::-webkit-scrollbar {
  background-color: var(--black);
  width: 0.2vw;
}

.editorDevice::-webkit-scrollbar-thumb {
  background-color: rgb(105, 105, 105);
  width: 0.2vw;
  border-radius: 10px;
}
.editorDeviceScreen {
  width: 20vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  height: min-content;
  margin-top: 2.5vh;
  margin-bottom: 2vh;
}
.editorItemContainer {
  width: 95%;
  height: 70vh;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  overflow-y: scroll;
}
.editorItemContainer::-webkit-scrollbar {
  background-color: var(--black);
  width: 0.2vw;
}

.editorItemContainer::-webkit-scrollbar-thumb {
  background-color: rgb(105, 105, 105);
  width: 0.2vw;
  border-radius: 10px;
}
.editorItemS {
  border: 1px solid #edede8;
  width: 6vw;
  margin-top: 0.5vh;
  height: 6.3vw;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7aa47;
  flex-direction: column;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);animation: pop 0.5s 0.2ms;
}
.editorItemM {
  border: 1px solid #edede8;
  width: 9vw;
  margin-top: 0.5vh;
  height: 9vw;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7aa47;
  flex-direction: column;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  animation: pop 0.5s 0.2ms;
}
.editorItemL {
  border: 1px solid #edede8;
  width: 18vw;
  margin-top: 0.5vh;
  height: 9vw;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7aa47;
  flex-direction: column;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);animation: pop 0.5s 0.2ms;
}
.editorItemXL {
  border: 1px solid #edede8;
  width: 18vw;
  margin-top: 0.5vh;
  height: 18vw;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7aa47;
  flex-direction: column;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);animation: pop 0.5s 0.2ms;
}
.editorItemS:hover {
  cursor: pointer;
  border: 2px solid #f98304;
}
.editorItemM:hover {
  cursor: pointer;
  border: 2px solid #f98304;
}
.editorItemL:hover {
  cursor: pointer;
  border: 2px solid #f98304;
}
.editorItemXL:hover {
  cursor: pointer;
  border: 2px solid #f98304;
}
.editorBtnContainer {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 95%;
}
.editorBtn {
  display: flex;
  color: #f98304;
  border: 2px solid #f98304;
  border-radius: 5px;
  margin-top: 2vh;
  width: 6vw;
  justify-content: center;
  align-items: center;
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  font-weight: bold;
}
.editorBtn:hover {
  color: #ffffff;
  background-color: #f98304;
  cursor: pointer;
}
.editorText {
  font-size: 18px;
  padding-top: 1vh;
  padding-bottom: 1vh;
}
.editorInput {
  border: 1px solid black;
  padding-left: 0.5vw;
  padding-top: 0.2vh;
  padding-bottom: 0.2vh;
  width: 80%;
}
.editorTitle {
  font-weight: bold;
  font-size: 20px;
  margin-top: 1vh;
  margin-bottom: 1vh;
}
.editorOption {
  background-color: #f7aa47;
  width: 24%;
  color: white;
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  border-radius: 5px;
}
.editorOption:hover {
  cursor: pointer;
}
.editorDelete {
  display: flex;
  color: #ff003e;
  border: 2px solid #ff003e;
  border-radius: 5px;
  margin-top: 2vh;
  width: 6vw;
  justify-content: center;
  align-items: center;
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  font-weight: bold;
}
.editorDelete:hover {
  color: #ffffff;
  background-color: #ff003e;
  cursor: pointer;
}
.editorIcon {
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.7));
}
.editorDropdown {
  width: 60%;
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  padding-left: 0.5vw;
}
