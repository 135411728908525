.banner-container {
  text-align: center;
  padding:  0;
}

.banner-container img {
  width: 75%;
  max-height: 50vh;
  object-fit: cover;
}

.banner-container .carousel-control-prev, .banner-container .carousel-control-next {
  width: 30%;
  opacity: 0.6;
}
  
/* @media only screen and (max-width: 768px) {
  .banner-container {
    height: 31vh;
  }

  .banner-container img {
    max-height: 28vh;
  }
} */

@media only screen and (max-width: 375px) {
  .banner-container {
    height: 31vh;
  }

  .banner-container img {
    max-height: 28vh;
  }
}
