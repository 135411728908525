.rde-main {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  margin-top: -4.2vh;
}

.rde-title {
  position: absolute;
  width: 100%;
  height: 60px;
  display: flex;
  box-shadow: 0 5px 5px -5px rgb(0 0 0 / 50%);
  z-index: 2000;
}

.redo {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.copyPaste {
  display: flex;
  justify-content: center;
}

.drawModeToggler {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.copyPasteBtn {
    padding: 6px 10px !important;
    margin: 0 2px !important;
    border-radius: 5px !important;
    border: 1px solid #0d6efd !important;
    background-color: transparent;
    color: #0d6efd;
}

#mapp {
  border-radius: 10px;
}

.borderBottom {
  border: none;

  height: 6vh;
  border-radius: 5px !important;

  /* border-bottom: 1px solid black !important;
  border-right: 1px solid black !important; */
  width: 3.5vw;
  background: white !important;
}

.fontsAndTexts {
  flex-direction: column;
}

#categoriesToggler:hover {
  background: transparent;
  text-decoration: none;
  color: green;
}

#categoriesDropdown {
  margin-right: 1.6vw !important;
}

.mapItemConfigInputs {
  border-radius: 50px!important;
  width: 30px;
  height: 30px; 
  border-radius: 50%;
  overflow: hidden;
  cursor:pointer;
}

.flexStart {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.coded {
  color: black;
}

.react-colorful {
  height : 140px!important;
}

#textInput {
  border-radius: 0px!important;
  padding-left: 1px;
  border: none;
  border-bottom: 1px solid black;
}

#textInput:focus {
  outline:none;
}

.leftSidebar {
  /* box-shadow: 0px 4px 17px 0px rgb(0 0 0 / 50%); */
  border-radius: 12px;
  border: 1px solid black;
  padding: 8px;
  padding-bottom: 24px;
  background: #1c4325;
  padding-top: 7%;
  padding-bottom: 14%;
}

.borderRight {
  border-right: 1px solid black;
}

.rde-editor {
  overflow: hidden;
  display: flex;
  box-shadow: 5px 0 5px -5px rgb(0 0 0 / 50%);
}

.rde-editor-items {
  position: relative;
  width: 240px;
  box-shadow: 5px 0 5px -5px rgb(0 0 0 / 50%);
  z-index: 900;
  transition: width 0.5s ease;
}

.inside-editor {
  margin-top: 3vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inside-editor > div {
  padding: 5px 0;
}

.editor-items-divs {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 9%;
}

.editor-items-divs-cats {
  position: relative;
  /* overflow: hidden; */
  width: 100%;
  /* height: 39%; */
}

.editor-items-divs-exhibits {
  position: relative;
  /* overflow: hidden; */
  width: 100%;
  /* height: 39%; */
}

.allDivs {
  position: absolute;
  inset: 0px;
  /* overflow: scroll; */
  /* margin-right: -17px;   
    margin-bottom: -17px; */
}

.rde-editor-canvas-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.rde-editor-header-toolbar {
  background: rgba(0, 0, 0, 0.05);
  transition: width 0.05s linear;
  display: flex;
  height: 40px;
  z-index: 800;
  -webkit-box-shadow: 0 5px 10px -5px rgb(0 0 0 / 50%);
  -moz-box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 10px -5px rgb(0 0 0 / 50%);
}

.rde-editor-canvas {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.rde-editor-configurations {
  position: relative;
  overflow-y: auto;
  width: 400px;
  right: 0;
  -webkit-box-shadow: -5px 0 5px -5px rgb(0 0 0 / 50%);
  -moz-box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.5);
  box-shadow: -5px 0 5px -5px rgb(0 0 0 / 50%);
  /* background: #fff; */
  transition: width 0.5s ease;
}

.rounded-circle-img {
  border-radius: 62%;
  width: 5.9vw;
  height: 8vh;
}

.tool {
  width: 60%;
}

#canvas {
  width: 30vw;
  height: 60vh;
  margin-top: 11%;
  border: 1px solid #1c4325 !important;
  border-radius: 3px;
}

.c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fabric-canvas-wrapper {
  border: solid 1px red;
  width: 30vw;
  height: 60vh;
  margin-top: 11%;
  margin-left: 25%;
}

.floor-canvas {
  width: 98%;
  height: 70vh;
  border: 1px solid rgb(203, 203, 203);
  background-color: rgba(236, 236, 236, 0.653);
  margin: 1vh 1%;
}

.editor-left-sidebar {
  padding: 0%;
  padding-right: 6%;
  height: 100%;
  text-align: center;
}

.editor-items {
  display: flex;
  flex-direction: column;
}

.editor-items > div {
  margin: 3px 0;
}

.accordion-item {
  border: 0;
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
  border-radius: 0s;
}

.fabric-canvas-wrapper {
  border: solid 1px red;
  width: 30vw;
  height: 60vh;
  margin-top: 11%;
  margin-left: 25%;
}

.editor-left-sidebar {
  padding: 4%;
  height: 100%;
  text-align: left;
}

.editor-items {
  display: flex;
  flex-direction: column;
}

.editor-items > div {
  margin: 3px 0;
}

.accordion-item {
  border: 0;
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
  border-radius: 0s;
}

.canvas-container #canvas {
  margin-top: 1% !important;
}

.css-1s2u09g-control {
  border-radius: 5px !important;
}

.preview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 18%;
}

.preview-container img {
  transform: none !important;
}

.preview-container img:hover {
  cursor: auto !important;
}

.bg-image-container-desktop {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../images/macbook-air-transparent-background.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 14% 5% 18% 5%;
}

.bg-image-container-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../images/mobile-bg-image.png");
  width: 80%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 14% 0 14.4% 0;
}

.preview-image-desktop {
  width: 70%;
  height: auto;
}

.preview-image-mobile {
  width: 74%;
  height: auto;
}
