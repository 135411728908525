
.bod {
    padding: 0;
    margin:0;
    background-image: url("../../images/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    
    background-attachment: fixed;
    background-position: center;
    /* min-height: 100vh;
    width: 100vw; */
    
}



.form-control {
    width: 82%!important;
}

.fullWidth {
    width: 100%;
}

.littleTop {
    margin-top: 1.8%;
}

.outterForm {
    margin-top: 6%;
}

.bi {
    margin-right: -4.5%;
    margin-top: 4%;
    cursor: pointer;
}

.overlay {
    position: absolute;
    width: 100%;
    height :100%;
    /* background-color: #1c4325; */
}


    /* .content {
        background: #1c4325;
        background-image: '../images/bg.png';
    } */

    .content {
        display: flex;
        /* width: 99vw; */
        justify-content: flex-end;
        align-items: flex-end;
        height: 75vh;
    }

    .content-inside {
        position: absolute;
        right: 0;
        top: 22vh;
        background-color: #d7dbc7;
        border: 2px solid gray;
        width: 41vw;
        display: flex;
        /* align-content: flex-end; */
        /* justify-content: flex-end; */
        height: 52vh;
        align-items: center;
        
        justify-content: center;
        border-bottom-left-radius: 12%;
        border-top-left-radius: 12%;
        box-shadow: inset 19px 14px 30px 1px #0000008a
    }

   /* .outterForm {

   } */

   .inline {
    display: inline-flex;
   }

  

   

   

   
  

    

    .btn-primaryNew:hover {
        color: #fff;
        background-color: #1c4325;
        border-color: #d7dbc7;
        transition: ease-in-out .1s;
    }

    .btn-primaryNew:focus {
        outline: none;
        background-color: none!important;
    }

    .btn-primaryNew:active {
        outline: none;
        background-color: none !important;

    }


    .btn-primaryNew {
        color: #fff;
        background-color: #f7aa47;
        border-color: #f1f1f1;
        width: 100% !important;
        margin: auto;
        border-radius: 12px;
        min-height: 5.3vh;
        transition: ease-in-out .4s;
    }

    #displayFooter {
        
        color: white;
    }

    /* @media (max-width: 1600px) {
        #agreeValid {
            margin-left: 12.5vw !important;
        }

        #agreeInvalid {
            margin-left: 12.5vw !important;
        }
    } */

    .download {
        display: flex;
        position: sticky;
        width: 10rem;
        /* top: 16rem; */
        left: 32rem;
    }

    #notV {
        display: none;
    }

    .gPlay {
        width: 100%;
        margin-top: -41%;
        margin-bottom: -31%;
        cursor: pointer;
    }

    .right {
        margin-left: 5%;
    }

    .footer {
        position: fixed;
        left: 23vw;
        bottom: 0;
        display: flex;
        justify-content: center;
        width: 60vw;
    }