:root {
  --bg-color-1: #035918;
  --bg-color-2: #1c4325;
  --color-1: #f7aa47;
  --color-2: #f98304;
  --color-3: #edede8;
  --color-4: #d7dbc7;
  --color-5: #ff0000;
  --color-6: #cf2222;
}

.react-bootstrap-table {
  width: 100% !important;
  border: 1px solid #d2d2d2;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 20px;
  box-shadow: 2px 2px 20px 0px;
  margin-bottom: 3vh;
}

.sr-only {
  display: none;
}

.table .bs-table-striped-bg {
  background-color: #d7dbc7 !important;
}

.react-bootstrap-table table {
  table-layout: auto;
}

.btn-primaryEnLog {
  color: #fff;
  background-color: #f7aa47;
  border-color: #f1f1f1;
  width: 183% !important;
  border-radius: 12px;
  margin: auto;
  min-height: 5.3vh;
  transition: ease-in-out 0.4s;
}

.deleteIcon {
  color: var(--color-5);
  cursor: pointer;
  font-size: 20px;
}

.deleteIcon:hover {
  color: var(--color-6);
  transition: ease-in-out;
}


/* .sendIcon {
  color: var(--color-3);
  cursor: pointer;
  font-size: 20px;
}

.sendIcon:hover {
  color: var(--color-4);
  transition: ease-in-out;
} */

.editIcon {
  color: var(--bg-color-1);
  cursor: pointer;
  font-size: 20px;
}

.editIcon:hover {
  color: var(--bg-color-2);
  transition: ease-in-out;
}

.qrIcon {
  background-color: var(--bg-color-1);
  border-color: var(--bg-color-1);
  color: var(--color-3);
  border-radius: 14px;
  cursor: pointer;
}

.qrIcon:hover {
  background-color: var(--bg-color-2);
  border-color: var(--bg-color-2);
  color: var(--color-4);
  transition: ease-in-out 0.1s;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
  border-radius: 6px;
  color: black;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: green;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-style: hidden;
}

.swal2-popup.swal2-modal {
  border-radius: 20px;
}

.swal2-confirm.swal2-styled.swal2-default-outline {
  border-radius: 20px;
  background-color: rgb(221, 51, 51) !important;
  border: none;
}

.swal2-confirm.swal2-styled.swal2-default-outline:focus {
  border: none;
}

.swal2-cancel.swal2-styled.swal2-default-outline {
  border-radius: 20px;
  background-color: black !important;
}

.swal2-confirm.swal2-styled {
  border-radius: 20px !important;
  background-color: #1c4325 !important;
}
