.mapConfigContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mapConfigTitle {
  color: black;
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-weight: bold;
  font-size: x-large;
}

.mapConfigInput {
  width: 80%;
  height: 5vh;
  padding-left: 1%;
  border-radius: 10px;
}

.mapConfigText {
  color: black;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.mapConfigEdit {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.mapConfigDelete {
  color: white;
  background-color: red;
  border-radius: 5px;
  padding: 2%;
  width: 10vw;
  margin-top: 1vh;
}

.mapConfigDelete:hover {
  cursor: pointer;
  background-color: rgb(192, 62, 62);
}

.clean-canvas-btn {
  width: 7vw;
  padding: 6px 10px !important;
  margin: 0 2px !important;
  border-radius: 5px !important;
  border: 1px solid red !important;
  background-color: transparent;
  color: red;
}

.clean-canvas-btn:hover {
  background-color: red;
  color: white;
  transition: 0.2s;
}

.save-update-button {
  width: 7vw;
  padding: 6px 10px !important;
  margin: 0 2px !important;
  border-radius: 5px !important;
  border: 1px solid #0d6efd !important;
  background-color: transparent;
  color: #0d6efd;
}

.save-update-button:hover {
  background-color: #0d6efd;
  color: white;
  transition: 0.2s;
}

.save-update-button-disabled {
  width: 7vw;
  padding: 6px 10px !important;
  margin: 0 2px !important;
  border-radius: 5px !important;
  border: 1px solid #0d6efd !important;
  background-color: transparent;
  color: #0d6efd;
  opacity: 0.5;
}

.close-modal-button {
  padding: 6px 10px !important;
  margin: 0 2px !important;
  border-radius: 5px !important;
  border: 1px solid #adb5bd !important;
  background-color: transparent;
  color: lightslategray;
}

.close-modal-button:hover {
  color: black;
  border: 1px solid black !important;
  transition: 0.2s;
}

.add-text-form {
  padding: 12px 14px;
  border-radius: 5px;
}

.add-new-map-btn {
  padding: 6px 10px !important;
  margin: 0 2px !important;
  border-radius: 5px !important;
  border: 1px solid #0d6efd !important;
  background-color: transparent;
  color: #0d6efd;
}

.add-new-map-btn:hover {
  background-color: #0d6efd;
  color: white;
  transition: 0.2s;
}

.edit-map-btn {
  padding: 6px 10px !important;
  margin: 0 2px !important;
  border-radius: 5px !important;
  border: 1px solid #1c4325 !important;
  background-color: transparent;
  color: #1c4325;
}

.edit-map-btn-disabled {
  padding: 6px 10px !important;
  margin: 0 2px !important;
  border-radius: 5px !important;
  border: 1px solid #1c4325 !important;
  background-color: transparent;
  color: #1c4325;
  opacity: 0.5;
}

.edit-map-btn:hover {
  background-color: #1c4325;
  color: white;
  transition: 0.2s;
}

.delete-map-btn {
  padding: 6px 10px !important;
  margin: 0 2px !important;
  border-radius: 5px !important;
  border: 1px solid red !important;
  background-color: transparent;
  color: red;
}

.delete-map-btn-disabled {
  padding: 6px 10px !important;
  margin: 0 2px !important;
  border-radius: 5px !important;
  border: 1px solid red !important;
  background-color: transparent;
  color: red;
  opacity: 0.5;
}

.delete-map-btn:hover {
  background-color: red;
  color: white;
  transition: 0.2s;
}

.form-control-add-edit-map {
  padding: 6px 10px !important;
  margin: 0 2px !important;
  border-radius: 5px !important;
}

.responsive-btn {
  padding: 6px 10px !important;
  margin: 0 2px !important;
  border-radius: 5px !important;
  border: 1px solid #1c4325 !important;
  background-color: transparent;
  color: #1c4325;
}

.responsive-btn:hover {
  background-color: #1c4325;
  color: white;
  transition: 0.2s;
}

.preview-canvas-btn {
  padding: 6px 10px !important;
  margin: 0 2px !important;
  border-radius: 5px !important;
  border: 1px solid #1c4325 !important;
  background-color: transparent;
  color: #1c4325;
}

.preview-canvas-btn:hover {
  background-color: #1c4325;
  color: white;
  transition: 0.2s;
}

.image-tabs button {
  border-radius: 5px !important;
  padding: 5px 5px !important;
}

@media screen and (max-width: 1450px) {
  .save-update-button-disabled {
    width: auto;
  }

  .clean-canvas-btn {
    width: auto;
  }
}
