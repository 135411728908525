#footer {
  padding: 2rem 0;
}

#footer a {
  text-decoration: none;
}

.newslatter-form {
  -webkit-box-align: center;
  align-items: center;
  margin-top: 1.2rem;
  padding: 0.9rem;
  box-shadow: rgb(60 45 111 / 10%) 0px 4px 8px, rgb(60 45 111 / 15%) 0px 1px 3px;
  border-radius: 10px;
}

.newslatter-form input {
  border: 0;
  border-radius: 10px;
  margin: 0 1rem;
  padding: 0 0.5rem;
}

.newslatter-form button {
  border: 0;
  background-color: transparent;
}

.social-icons {
  width: 30%;
}

.newslatter-form-icons,
.social-icons {
  color: rgba(59, 130, 246, 0.5);
}

@media only screen and (max-width: 768px) {
  .single-footer-widget {
    margin: 1rem 0;
    text-align: center;
  }

  #footer {
    padding: 0 0.5rem;
  }

  .social-icons {
    width: 100%;
    padding: 0 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .social-icons {
    width: 50%;
  }

  .newslatter-form input {
    border-radius: 5px;
    margin: 0 0.3rem;
    padding: 0 0.2em;
  }
}
