label {
    color: white;
}

.line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.rowFlex {
    display: flex;
    flex-direction: row;
    
}


/* .dropdown-menu {
    display: block;
} */

@media (max-width: 1600px) {
    .navbar-nav {
       margin-top: 0!important;
    }
}

.xenagosHeader {
    background-color: #1c4325;
    height: 8.5vh;
}


.headingHeader {
    padding-top: 35px;
}

.col-md-3 {
    padding: 15px;
}

.imageFix {
    width: 80px;
    height: 63px;
}
/*.linkTitle{
      color:#808080 !important;
  }*/

#xenagosLogo {
    width: 56%;
    margin-top: 1.5vh;
}

#userWelcome {
    color: #ffffff;
    margin-top: 1em;
}

.displayStyle {
    height: 4.8vh;
    margin-top: 1.2vh;
}

.logoutBtn {
    display: flex;
    margin-top: 1em;
    justify-content: space-between;
    height: auto;
}

.userBtn {
    display: flex;
    margin-top: 0.5em
}
/*.hiddenText {
    background-color: #3b3b3b;
    color: orange;
    display: none;
}*/
#datalogTitle:hover {
    cursor: pointer;
}



a:hover {
    text-decoration: none;
}

.disabled {
    color: grey;
    background-color: rgb(0, 0, 0, .2);
    pointer-events: none;
}

#siteSelector {
    background-color: orange !important;
    font-size: 14px;
    color: white !important;
    border-radius: 20px;
    border: none;
    cursor: pointer; 
}

/* span {
    font-size: 21px;
    transition: 0.6s ease-in-out;
} */

.pageHead {
    width: 100%;
    height: 72px;
    margin-bottom: 1vh;
    margin-top: -4px;
    border-bottom: 1px solid rgb(0 0 0 / 27%);
    box-shadow: 1px 3px 12px 0px;
    /*background: rgb(233,233,233);*/
}

.backgroundBoxes {
    background: #edede8;
}

.heightImg {
    height: 3.8vh;
}

.dropdown .btn:focus {
    background: #1c4325 !important;
    outline: none;
    box-shadow: none;
}

.dropdown .btn:hover {
    background: #1c4325 !important;
}

#enPath {
    cursor: pointer;
}

    #enPath:hover {
        background: orange;
    }

#elPath:hover {
    background: orange;
}


#elPath {
    cursor: pointer;
}

.textTitle {
    color: #1c4325;
}