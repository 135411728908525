.icon {
    /* margin-bottom: 0.5vh;
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-right: 3vw;
    margin-top: 0.4vh;
    height: auto; */
  }
  
  #filterLang {
    border-radius: 20px;
    background-color: rgb(28, 67, 37);
    color: white;
    cursor: pointer;
    padding: 8px 25px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    /* color: black; */
    /* border-radius: 50px; */
    border: 2px solid green;
    transition: all 0.25s ease;
  }
  
  .active {
    color: white;
    background: #1c4325;
  }
  
  /* .filterMediaType {
      justify-content: center;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
  }
  
  .items {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-right: 3vw;
  }
  
  .item {
      color: white;
      background: green;
      border-radius: 20px;
  } */
  
  .wrapper {
    /* margin: 1.1vh 27vh;
    max-width: 1100px;
    margin-bottom: 0.4vh; */
  }
  
  /* .wrapper nav {
          display: flex;
          justify-content: center;
      } */
  
  .wrapper .items {
    /* display: flex;
    max-width: 720px;
    width: 100%;
    justify-content: space-between; */
  }
  
  .items span {
    padding: 7px 25px;
    margin: 0.2rem;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    color: black;
    border-radius: 20px;
    border: 1px solid #1c4325;
    transition: all 0.25s ease;
  }
  
  .items span.active,
  .items span:hover {
    color: white;
    background: #1c4325;
    transition: all 0.25s ease;
  }
  
  .searchbar {
    border: none;
    border-bottom: 2px solid #1c4325;
  }
  
  .searchbar:focus {
    outline: none;
  }
  
  /* .searchWrapper {
    margin-top: 1.8vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
  } */
  