.modal_content {
    border-radius: 2%!important;
    min-width: 110%!important;
}

.textCenter {
    text-align: center;
}

.modal_header {
    height: 4.9vh!important;
}

#updateImage {
    /* width: 60%; */
    height: 38vh;
    max-width: 100%;
}

.xenagosButton {
    background: #d7dbc7;
    color: #035918;
    font-weight: 500;
    border-radius: 12px;
    outline: none;
    border: none;
    min-height: 50px;
    height: auto;
    width: auto;
    min-width: 100px;
    padding-right: 0.8vw;
    padding-left: 0.8vw;
    transition: 0.5s ease-in-out;
}

.modal_footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center!important;
    justify-content: center!important;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}

#photoPlaceHolder {
    display: flex;
    flex-direction: column;
}

.backwards {
    margin-left: 1vw;
}

#objectUpdateSerialNumber {
    width: 100%!important;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 15px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0!important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
}

.css-1rhbuit-multiValue {
    background-color: #f7aa47!important;
}