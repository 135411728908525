.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 65vh !important;
}

.image-gallery-thumbnails-wrapper{
    display:none !important;
}

.spinnerField {
    position: absolute;
    left: 49%;
    top: 60%;
}


/* .spinnerDel {
    position: absolute;
    left: 49%;
    display:none;
    top: 45%;
} */

/* progress::-webkit-progress-bar {
    background-color: yellow!important;
    border-radius: 7px!important;
  }
  progress::-webkit-progress-value {
    background-color: blue!important;
    border-radius: 7px!important;
    box-shadow: 1px 1px 5px 3px rgba( 255, 0, 0, 0.8 )!important;
  } */

  progress {
    transition: ease-in 0.6s;
  }

.spinnerStep3 {
    position: absolute;
    left: 49%;
    top:34%;
}

.spinnerUpload {
    position: absolute;
    left: 49%;
    display:none;
    top:45%;
}

.spinnerTrans {
    position: absolute;
    left: 50%;
    top: 11%;
}


.toggleDisplay {
    opacity: 0.5;
}

/* .row {
    opacity: 0.5;
} */
/* .react-loading-skeleton {
    margin-top: 2vh;
    width: 20%;
    height: 20vh;
} */

/* .overlayed > div > span {

flex-direction: row;
display: flex;
flex-wrap: wrap;
justify-content: center;
  } */
.image-gallery-icon.image-gallery-play-button {
    display:none;
}

.image-gallery-icon.image-gallery-fullscreen-button {
    display:none;
}

.video_container {
    margin-left: 0.7vw;
    margin-right: 0.7vw
}

video {
    width: 100%;
    height: auto;
    cursor: pointer;
}

/* input:checked {
    background: green;
} */

img {
    cursor: pointer;
}

.Carousel__Item-sc-hyhecw-5.jjfwLM {
    /* width: 22vw; */
    /* height: 31vh; */
    width: 100%;
    height: 31vh;
}

/* .greenCheck:checked:before {
    background-color: green;
} */

.descriptionLang {
    border:none;
}

.descriptionLang:focus {
    outline:none;
}

.title {
    margin-top: 1.4vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}

.description_Media {
    border: none;
    width: 55%;
    text-align: center;
    background: transparent;
    text-overflow: ellipsis;
    margin-right: 0.4vw;
    background: #ffa50014;
}

.description_Media:focus {
    outline:none;
}

.save_Description {
    border-radius:20px;
    display:none;
}

.image-gallery-slide .image-gallery-description {
    background: rgba(0,0,0,.4);
    bottom: 6vy;
    bottom: 0vh!important;
    /* width: 43vw!important; */
    margin-top: 0vh;
    left: 37vw!important;
     /*justify-content: center; */
    right: 36vw;
    margin-right: 0vw;
    color: #fff;
    /* left: 94px!important; */
    line-height: 1;
    padding: 10px 20px;
    position: sticky;
    white-space: inherit;
}

.backdrop {
    position: fixed;
    /* opacity: 0.9; */
  
    background: #132c19;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    
}

.crop-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
    z-index: 99;
    /* background: #1c4325; */
    /*background: linear-gradient(#e66465, #9198e5);*/
}

.controls {
    position: fixed;
    bottom: 0px;
    left: 0%;
    width: 100%;
    height: 80px;
}

.controls-upper-area {
    text-align: center;
}

.slider {
    width: 50%;
    cursor: pointer;
}

.button-area {
    text-align: center;
    
}

.buttonArea {
    margin-left: 10px;
    margin-right: 10px;
    
    color: white;
    
}

.aspectRatio {
    color: white;
    font-size: 20px
}

.form-select-xs {
    display: inline!important;
    width: 5%!important;
    margin-left: 1.5%;
    border-radius: 10px;
}

.roundBox {
    border-radius:3.5%;
    box-shadow: 2px -1px 3px 2px #00000085;
    width:100%;
    height: 100%;
}

.reactEasyCrop_Image.reactEasyCrop_Contain {
    border-radius: 3%;
}

.reactEasyCrop_CropArea {
    border-radius: 3%;
}

.titleVid {
    margin-top:0.6vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}

.titleVid360 {
    margin-top:0.6vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.titleAud {
    margin-top:0.6vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}

.dZkckO {
    
    top: calc(50% - 49.5px)!important;
    right: -2vw!important;
   
}


.bBfHpH {
    top: calc(50% - 49.5px)!important;
    left: -2vw!important;
}


.all_filters {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-bottom: 5vh;
}

.checkboxForCarousel {
   
    position: relative;
    margin-top: 0px;
    left: 84.5%;
    top: -36%;
    z-index: 999;
}

.checkboxForCarouselVid {
   
    position: relative;
    margin-top: 0px;
    left: 44.5%;
    top: -36%;
    z-index: 999;
}

.imageHolder {
    width: 100%;
    height: 90%;
}


#add_media {
    background: #d7dbc7;
    color: #035918;
    font-weight: 500;
    border-radius: 12px;
    outline: none;
    border: none;
    min-height: 50px;
    height: 5vh;
    width: auto;
    min-width: 100px;
    padding-right: 0.8vw;
    padding-left: 0.8vw;
    transition: 0.5s ease-in-out;
    margin-top:1.3vh;
    margin-right:5vw;
}

.thumbnailPreview {
    height: 230px;
    width: 250px;
    margin: 10px;
    float: left; 
    box-shadow: 17px 9px 10px 0px #00000042;
  border-radius: 8px;	
}
  
.thumbnailPreviewVid {
    height: auto;
    width: 100%;
    margin: 10px;
    float: left; 
    box-shadow: 17px 9px 10px 0px #00000042;
  border-radius: 8px;	
}


  .thumbnail {
    height: 170px;
    width:220px;
    margin: 10px;
    float: left; }
  
  #clear {
    display: none; }
  
  #mediaDelete {
    border: 4px solid #cccccc;
    float: left;
    position: relative;
    margin: 0 auto; }
  
  #mediaUpload {
    display: block;
    margin: 0 auto;
    position: relative; }
  
  #result {
    margin: 0 auto; }

    #divResults {
        background: rgb(28 67 37 / 40%);
        padding: 35px;
        border: 2px solid rgb(28, 67, 37);
        /* box-shadow: 2px 0px 1px 1px black; */
        margin-bottom: 3vh;
        border-radius: 8px;
        margin-top: 1vh;

    }

    .labelUpload {
        background: floralwhite;
    }

    #result {
        overflow-x:auto;
        margin-bottom:2vh;
    }

    
    #result::-webkit-scrollbar {
         width: 5px;
         height: 10px;
     }
     
     #result::-webkit-scrollbar-track {
         -webkit-box-shadow: inset 0 0 6px rgb(223, 184, 184); 
         border-radius: 10px;
         
         
     }
     
     #result::-webkit-scrollbar-thumb {
         border-radius: 10px;
         -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.7); 
         background:#1c4325;
     }

     .modal-content {
        border-radius: 1rem!important;
     }

     .switch {
        position: relative;
        display: inline-block;
        width: 80px;
        height: auto; }

        .audioFiller {
            height: auto!important;
            width: auto!important;
            background: transparent;
            margin-top: 1.9vh;
        }

        .form-check-input:checked {
            background-color: #198754!important;
            border-color: #198754;
        }

        #uploadButton {
            /* width: 10%!important; */
            border-radius: 18px;
        }

       #message {
        text-align: center;
       }

       .saveDel {
            display: flex;
            flex-direction: row;
            justify-content: center;
       }

       .audioCheckbox {
        position: relative;
        margin-top: 0px;
        left: 37.5%;
        /* top: 80%; */
        z-index: 999;
       }

       .toggler360 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-left: -30.5%;
       }

       .spinner {
        margin-left: 12%;
        margin-top: -8%;
        position: absolute;
       }