@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

:root {
  --color-1: #f7aa47;
  --color-2: #f98304;
  --color-3: #edede8;
  --color-4: #d7dbc7;
  --color-5: #ff0000;
  --color-6: #cf2222;
  --color-7: #035918;
  --color-8: #1c4325;
  --color-9: #ccd98f;
  --color-10: #a8b378;
  --color-11: #ffffff;
  --color-12: #fafafa;
  --color-13: #000;
}

.addNewButton {
  color: var(--color-11);
  background-color: var(--color-1);
  border-color: var(--color-1);
  font-weight: 500;
}

.addNewButton:hover,
.addNewButton:focus {
  color: var(--color-12);
  background-color: var(--color-2);
  border-color: var(--color-2);
}

.closeButton {
  color: var(--color-7);
  background-color: var(--color-3);
  border-color: var(--color-3);
  font-weight: 400;
}

.closeButton:hover,
.closeButton:focus {
  color: var(--color-7);
  background-color: var(--color-4);
  border-color: var(--color-4);
  font-weight: 400;
}

.attachment-categories {
  color: var(--color-7);
}

.nav-pills .nav-link {
  color: var(--color-2);
}

.nav-pills .nav-link:hover {
  color: var(--color-7);
}

.nav-pills .nav-link.active {
  color: var(--color-11) !important;
  background-color: var(--color-1) !important;
  font-weight: 500;
}

.backwards,
.forward {
  background-color: var(--color-7);
  border-color: var(--color-7);
}

.backwards:hover,
.forward:hover,
.backwards:focus,
.forward:focus {
  background-color: var(--color-8);
  border-color: var(--color-8);
}

.css-12jo7m5 {
  color: var(--color-11) !important;
}

.tab-save-button {
  color: var(--color-11);
  background-color: var(--color-7);
}

.tab-save-button:hover, .tab-save-button:focus  {
  color: var(--color-11);
  background-color: var(--color-8);
}

.tabs-ul-language {
  color: var(--color-1);
  width: 100% !important;
  margin: 0;
  padding: 0;
}

.tabs-ul-language .acti {
  padding: 0.1rem 0 0.1rem 1rem;
  font-weight: 500 !important;
}

.tabs-ul-language .acti:hover {
  cursor: pointer;
  color: var(--color-7);
  transition: color 0.15s ease-in-out;
}

.tabs-ul-language .activeUl {
  color: var(--color-11) !important;
}

.tabs-ul-language .activeUl {
  color: var(--color-11);
  background-color: var(--color-1);
  border-radius: 0.25rem;
  width: 100% !important;
  transition: color 0.15s ease-in-out;
}

.fields-content .row {
  margin-top: 0 !important;
  margin-bottom: 0.5rem !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Poppins', sans-serif;*/
}

@media (min-width: 801px) {
  .content-wrapper {
    /* -moz-transform: scale(0.95, 0.95);*/
    zoom: 0.95;
    zoom: 95%;
  }
}

@media (min-width: 1380px) {
  .content-wrapper {
    /* -moz-transform: scale(1, 1);*/
    zoom: 1;
    zoom: 100%;
  }
}

iframe {
  width: 72%;
  z-index: 99;
}

::selection {
  color: #fff;
  background: green;
}

/* body {
    padding: 10px;
} */

.wrapper {
  margin: 100px auto;
  max-width: 1100px;
}

.wrapper nav {
  display: flex;
  justify-content: center;
}

.wrapper .items {
  display: flex;
  max-width: 720px;
  width: 100%;
  justify-content: space-between;
}

.items span {
  padding: 7px 25px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  color: black;
  border-radius: 50px;
  border: 2px solid green;
  transition: all 0.45s ease;
}

.items span.active,
.items span:hover {
  color: orange;
  background: #1c4325;
  transition: all 0.45s ease;
}

.galleryDefault {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.galleryDefault .image {
  width: calc(100% / 4.3);
  padding: 7px;
  height: 10%;
}

.galleryDefault .image span {
  display: flex;
  width: 100%;
  height: 60%;
  overflow: hidden;
}

.galleryDefault .image img {
  width: 100%;
  height: 21vh;
  vertical-align: middle;
  transition: all 0.3s ease;
}

.galleryDefault .image:hover img {
  transform: scale(1.1);
}

.galleryDefault .image.hide {
  display: none;
}

.galleryDefault .image.show {
  animation: animate 0.4s ease;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.gallery .image {
  width: calc(100% / 4.3);
  padding: 7px;
  height: 10%;
}

.gallery .image span {
  display: flex;
  width: 100%;
  height: 60%;
  overflow: hidden;
}

.gallery .image img {
  width: 100%;
  height: 21vh;
  vertical-align: middle;
  transition: all 0.3s ease;
}

.gallery .image:hover img {
  transform: scale(1.1);
}

.gallery .image.hide {
  display: none;
}

.gallery .image.show {
  animation: animate 0.4s ease;
}

.gallery .video {
  /*width: 40%;*/
  /*margin-top: -3%;*/
  padding: 7px;
}

.gallery .video span {
  display: flex;
  width: 100%;
  overflow: hidden;
}
/* .gallery .audio{
  width: 40%;
  padding: 7px;
}
.gallery .audio span{
  display: flex;
  width: 100%;
  overflow: hidden;
} */

/* .gallery .video img{
  width: 100%;
  vertical-align: middle;
  transition: all 0.3s ease;
} */
/* .gallery .video:hover img{
  transform: scale(1.1);
} */
.gallery .video.hide {
  display: none;
}

.gallery .video.show {
  animation: animate 0.4s ease;
}

.gallery .audio.hide {
  display: none;
}

.gallery .audio.show {
  animation: animate 0.4s ease;
}

@keyframes animate {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.image {
  cursor: pointer;
}

.video {
  cursor: pointer;
}

.audio {
  cursor: pointer;
}

.preview-box {
  position: fixed;
  top: 50%;
  left: 57%;
  transform: translate(-50%, -50%) scale(0.9);
  background: #fff;
  max-width: 850px;
  width: 100%;
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  border-radius: 3px;
  padding: 0 5px 5px 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.preview-box.show {
  opacity: 1;
  pointer-events: auto;
  transform: translate(-50%, -50%) scale(1);
  transition: all 0.3s ease;
}

.preview-box .details {
  padding: 13px 15px 13px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.details .title {
  display: flex;
  font-size: 18px;
  font-weight: 400;
  max-width: 12vw;
  max-height: 7vh;
}

.details .title p {
  font-weight: 500;
  margin-left: 5px;
}

.details .icon {
  color: #007bff;
  font-size: 24px;
  cursor: pointer;
}

.preview-box .image-box {
  width: 100%;
  display: flex;
  height: 55vh;
}

.image-box img {
  width: 100%;
  border-radius: 0 0 3px 3px;
}

.preview-box .video-box {
  width: 100%;
  display: flex;
}

.preview-box .audio-box {
  width: 100%;
  display: flex;
}

.video-box video {
  width: 100%;
  border-radius: 0 0 3px 3px;
}

.video-box audio {
  width: 100%;
  border-radius: 0 0 3px 3px;
}

.shadow {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  display: none;
  background: rgba(0, 0, 0, 0.4);
}

/* .checkboo {
    width:19px;
} */

.buttons {
  position: inherit;
  top: -23%;
  /*display: inline-flex;*/
  left: 30%;
  opacity: 0;
  transition: all 0.3s ease;
  text-align: center;
}

button {
  outline: none;
}

.image:hover .buttons {
  opacity: 1;
  transition: all 0.3s ease;
}

.video:hover .buttons {
  opacity: 1;
  transition: all 0.3s ease;
}

.audio:hover .buttons {
  opacity: 1;
  transition: all 0.3s ease;
}

.preview {
  position: inherit;
  /* display: inline-flex; */

  transition: all 0.3s ease;
  text-align: center;
  opacity: 1;
  float: left;
  left: 85%;
  top: 5%;
}

.far {
  cursor: pointer;
}

.fa-edit {
  font-size: 28px;
  color: #1c4325;
  border: #1c4325;
}

.fa-trash-alt {
  font-size: 28px;
  color: red;
  border: #1c4325;
}

.shadow.show {
  display: block;
}

@media (max-width: 1000px) {
  .galleryDefault .image {
    width: calc(100% / 3);
  }
}

@media (max-width: 800px) {
  .galleryDefault .image {
    width: calc(100% / 2);
  }
}

@media (max-width: 1000px) {
  .gallery .image {
    width: calc(100% / 3);
  }
}

@media (max-width: 800px) {
  .gallery .image {
    width: calc(100% / 2);
  }
}

@media (max-width: 700px) {
  .wrapper nav .items {
    max-width: 600px;
  }

  nav .items span {
    padding: 7px 15px;
  }
}

@media (max-width: 600px) {
  .wrapper {
    margin: 30px auto;
  }

  .wrapper nav .items {
    flex-wrap: wrap;
    justify-content: center;
  }

  nav .items span {
    margin: 5px;
  }

  .gallery .image {
    width: 100%;
  }
}

.loading {
  position: relative;
  /*top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background-color: #eceaea;
    background-image: url('https://larosdev.ath.cx/xenagosrhodesdev/en/images/index/loadbar.gif');
    background-size: 50px;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10000000;*/
  /*opacity: 0.5;*/

  animation: loading 1s linear infinite alternate;
}

@keyframes loading {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 0.85;
  }
}
