.mapProperties {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
}
.mapPropertiesTitle {
  color: black;
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-weight: bold;
  font-size: x-large;
}
.mapPropertiesInput {
  width: 80%;
  height: 5vh;
  padding-left: 1%;
  border-radius: 10px;
}
.mapPropertiesText {
  color: black;
  margin-top: 1vh;
  /* margin-bottom: 1vh; */
}
.mapPropertiesEdit {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mapPropertiesOptions {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.mapPropertiesItem {
  display: flex;
  justify-content: start!important;
  padding: 12px 25px;
  margin: 2% 0;
  width: 13vw;
  border: 1px solid #1c4325;
  border-radius: 5px !important;
  background-color: transparent;
  color: #1c4325;
  font-weight: 500;
  justify-content: center;
}

.mapPropertiesItem:hover {
  background-color: #1c4325;
  color: white;
  transition: 0.2s;
}

.tab-content {
  width: 100% !important;
}

.mapEditContainer {
  border: 1px solid #1c4325;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 2% 5%;
}
.mapPropertiesBtn {
  color: white;
  background-color: #1c4325;
  border-radius: 5px;
  padding: 5%;
  width: 6vw;
  margin-top: 1vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mapPropertiesBtn:hover {
  cursor: pointer;
}
.mapPropertiesUpload {
  background-color: #dee2e6;
  border-radius: 5px;
  padding: 1%;
  width: 80%;
  margin-top: 1vh;
}
.mapPropertiesMarker {
  width: 90%;
  background-color: white;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  margin-top: 1vh;
  height: 8vh;
}
.mapPropertiesMarker:hover {
  cursor: pointer;
  background-color: #dee2e6;
}
.mapPropertiesLabel {
  color: #1c4325;
  margin-left: 1%;
}

.mapPropertiesContainer {
  position: absolute;
  left: 0px;
  width: 20%;
}
.mapPropertiesIcon {
  position: absolute;
  top: 50%;
  left: 50%;
}
.mapPropertiesDelete {
  color: white;
  background-color: red;
  border-radius: 5px;
  padding: 1%;
  width: 80%;
  margin-top: 1vh;
}
.mapPropertiesDelete:hover {
  cursor: pointer;
  background-color: rgb(192, 62, 62);
}
.mapPropertiesTab {
  /* width: 10vw; */
  /* height: 6vh; */
  display: flex;
  align-items: center;
  margin-top: 2vh;
  /* box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5); */
  background-color: white;
  padding-left: 1%;
  justify-content: center;
  font-weight: bold;
  color: #1c4325;
}
