.bodC {
  padding: 0;
  margin: 0;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("../../images/background-cp.png");
  background-size: cover;
  background-repeat: no-repeat;

  background-attachment: fixed;
  background-position: center;
}

.anch {
  margin-top: 3vh;
}

label {
  color: white;
}

@media (max-width: 1600px) {
  .navbar-nav {
    margin-top: -2.3rem !important;
  }
}

.xenagosHeader {
  background-color: #1c4325;
  height: 11vh;
}

.headingHeader {
  padding-top: 35px;
}

.col-md-3 {
  padding: 15px;
}

.imageFix {
  width: 80px;
  height: 63px;
}

#xenagosLogo {
  width: 300px;
}

#userWelcome {
  color: #ffffff;
  margin-top: 1em;
}

.logoutBtn {
  display: flex;
  margin-top: 1em;
  justify-content: space-between;
}

.userBtn {
  display: flex;
  margin-top: 0.5em;
}

#datalogTitle:hover {
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

a {
  text-decoration: none;
}

span {
  /* font-size: 23px; */
  transition: 0.6s ease-in-out;
}

.pageHead {
  width: 100%;
  height: 72px;
  margin-bottom: 1vh;
  margin-top: -4px;
  border-bottom: 1px solid rgb(0 0 0 / 27%);
  box-shadow: 1px 3px 12px 0px;
}

.backgroundBoxes {
  background: #edede8;
}

.dropdown .btn:focus {
  background: #1c4325 !important;
  outline: none;
  box-shadow: none;
}

.dropdown .btn:hover {
  background: #1c4325 !important;
}

#enPath {
  cursor: pointer;
}

#enPath:hover {
  background: orange;
}

#elPath:hover {
  background: orange;
}

#elPath {
  cursor: pointer;
}

.textTitle {
  color: #1c4325;
  font-size: 21px;
  margin-left: 0.8vw;
}

.fromTop {
  margin-top: 5vh;
}

.linkTitle {
  margin-top: 2vh;
  margin-right: 1vh;
  padding-bottom: 3vh;
}

.imageFix {
  border-radius: 8px;
  box-shadow: 7px 9px 10px 0px #00000085;
}

.control-panel-card {
  background-color: #ffffff;
  border-radius: 20px;
  width: 65%;
  cursor: pointer;
  padding: 1.4rem 0;
  border: 0;
}

.control-panel-card img {
  height: 7vh;
  width: auto;
}

.control-panel-card div {
  padding-top: 1rem;
}

.control-panel-card div p {
  color: #035918;
  font-weight: 400;
  font-size: 2vh;
}

.control-panel-card:hover {
  background-color: #ffa50047;
  transition: 0.3s;
}

.control-panel-card:hover div p {
  color: #1c4325;
  transition: 0.3s;
}

@media (max-width: 991px) {
  .control-panel-card img {
    height: 8vh;
    width: auto;
  }
  .control-panel-card div p {
    font-size: 2.1vh;
  }
}
